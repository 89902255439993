import TeamsGenerator from "./components/TeamsGenerator/TeamsGenerator";
import "./app.css";

function App() {
  return (
      <TeamsGenerator/>
  );
}

export default App;
