import {useContext, useState} from "react";
import {TeamsGeneratorContext} from "../TeamsGenerator";
import classNames from "classnames";

export default function GenerateSettings() {

    const {teams, teamsQuantityError, setTeamsQuantityError, teamsQuantity, setTeamsQuantity, users, generateTeams, generateSetting, setGenerateSetting,SETTINGS} = useContext(TeamsGeneratorContext);

    const TeamsCountSetter = () => {

        const onIncrease = () => {
            setTeamsQuantityError(null)
            if (teamsQuantity < Math.ceil(users.length / 2)) {
                setTeamsQuantity(teamsQuantity + 1);
            }else{
                setTeamsQuantityError('Dodaj większą liczbę uczestników!')
            }
        }

        const onDecrease = () => {
            setTeamsQuantityError(null)
            if (teamsQuantity > 1) {
                setTeamsQuantity(teamsQuantity - 1);
            }else{
                setTeamsQuantityError('Zero drużyn? To po co losować?!')
            }
        }

        return (
            <div className={'flex w-full justify-between items-center border-y border-black'}>
                <button className={'border-amber-500 border py-2 px-4 bg-amber-500'} onClick={onDecrease}>-</button>
                <p>{teamsQuantity}</p>
                <button className={'border-amber-500 border py-2 px-4 bg-amber-500'} onClick={onIncrease}>+</button>
            </div>
        );
    }

    const CountTeamsOrParticipants = () => {
        return (
            <div className={'grid grid-cols-2 border-b border-amber-500 text-xs md:text-md'}>
                <button className={classNames('rounded-t p-2', generateSetting === SETTINGS.PER_TEAMS ? 'bg-amber-500' : null)} onClick={() => setGenerateSetting(SETTINGS.PER_TEAMS)}>Liczba drużyn</button>
                <button className={classNames('rounded-t p-2', generateSetting === SETTINGS.PER_USERS ? 'bg-amber-500' : null)} onClick={() => setGenerateSetting(SETTINGS.PER_USERS)}>Liczba członków zespołu</button>
            </div>
        );
    }

    return (
        <div className={'grid grid-cols-1 border border-amber-500 rounded mx-4'}>
            <CountTeamsOrParticipants/>
            <TeamsCountSetter/>
            {teamsQuantityError ? <p className={'text-red-600 text-center text-sm bg-amber-500'}> {teamsQuantityError}</p> : null}

            <button className={'py-4 px-4 bg-amber-500 w-full'} onClick={generateTeams}>{teams.length > 0 ? 'Losuj ponownie' :'Losuj drużyny'}</button>
        </div>
    );
}