import {createContext, createRef, useState} from "react";
import UserManagement from "./UserManagement/UserManagement";
import GenerateSettings from "./GenerateSettings/GenerateSettings";
import GeneratedTeams from "./GeneratedTeams/GeneratedTeams";
import classNames from "classnames";

export const TeamsGeneratorContext = createContext({});
export default function TeamsGenerator(){
    const SETTINGS = {
        PER_TEAMS: 'PER_TEAMS',
        PER_USERS: 'PER_USERS',
    }
    const [users, setUsers] = useState([]);
    const [teamsQuantity, setTeamsQuantity] = useState(1);
    const [teams, setTeams] = useState([]);
    const [generateSetting, setGenerateSetting] = useState(SETTINGS.PER_TEAMS);
    const [teamsQuantityError, setTeamsQuantityError] = useState(null);
    const bodyRef = createRef();

    const addUser = (username) => {
        setUsers(oldUsers => [...oldUsers, username]);
        recalculateMaxTeams();
    }

    const removeUser = (index) => {
        setUsers(users.filter((item, key) => key !== index))
        recalculateMaxTeams();
    }

    const generateTeams = () => {
        setTeamsQuantityError(null);
        setTeams([]);
        let availableUsers = users.map(item => item);

        let teamsNumbers = teamsQuantity;

        if (generateSetting === SETTINGS.PER_TEAMS) {
            teamsNumbers = Math.ceil(availableUsers.length / teamsQuantity);
        }

        while (availableUsers.length > 0) {
            let newTeam = [];
            for (var i = 0; i < teamsNumbers; i++) {
                if (availableUsers.length > 0) {
                    let userKey = getRandomInt(0, availableUsers.length);
                    newTeam.push(availableUsers[userKey])
                    availableUsers.splice(userKey, 1);
                }
            }
            setTeams(oldTeams => [...oldTeams, newTeam]);
        }

        scrollTop();
    }

    const getRandomInt = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min;
    }

    const recalculateMaxTeams = () => {
        let maxTeams = Math.ceil(users.length / 2);
        if(maxTeams < 2) maxTeams = 2;
        if(teamsQuantity > maxTeams) setTeamsQuantity(maxTeams);
    }

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    return (
        <TeamsGeneratorContext.Provider
            value={{
                users,
                addUser,
                removeUser,
                teamsQuantity,
                setTeamsQuantity,
                generateTeams,
                teams,
                generateSetting,
                setGenerateSetting,
                recalculateMaxTeams,
                setTeamsQuantityError,
                teamsQuantityError,
                SETTINGS,
                scrollTop,
            }}>
            <div className={'bg-stone-800 min-h-screen text-white p-4 min-w-[200px]'} ref={bodyRef}>
                <h1 className={'text-center text-4xl mb-4 font-black'}>Generator drużyn</h1>

                <div className={classNames('grid gap-4 container mx-auto items-start', teams.length > 0 ? 'md:grid-cols-2' : 'max-w-3xl')}>
                    <GeneratedTeams/>
                    <div className={'grid gap-2'}>
                        <UserManagement/>
                        <GenerateSettings/>
                    </div>
                </div>
            </div>
        </TeamsGeneratorContext.Provider>
    )
}