import RandomAvatar from "./Avatar/RandomAvatar";

export default function User({username, number, onRemove}) {
    return (
        <div className={'border flex rounded border-amber-500 bg-stone-900 justify-between items-center'}>
            {number ? <div className={'block h-full items-center bg-amber-500 flex w-8 py-2 justify-center'}>{number}</div> : null}
            <RandomAvatar className={'w-12 my-2'} seed={username}/>
            <div className={'capitalize w-full px-2'}>{username}</div>
            {onRemove ? <button className={'block h-full bg-red-800 py-2 px-4'} onClick={onRemove}>x</button> : null}
        </div>
    );
}