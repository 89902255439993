import {useContext} from "react";
import {TeamsGeneratorContext} from "../TeamsGenerator";
import User from "../User/User";

export default function GeneratedTeams({...props}){
    const {teams} = useContext(TeamsGeneratorContext)

    const Team = ({users, index}) => {
        return (
            <div>
                <p className={'font-bold text-sm'}>Drużyna {index + 1}</p>
                <div className={'grid gap-2'}>
                    {users.map((user, index) => {
                        return <User key={index} number={index+1} username={user} />
                    })}
                </div>
            </div>
        );
    }
    return (
        teams.length > 0 ? <div className={'rounded border-amber-500 p-4 grid gap-4'}>
            <p className={'text-center text-2xl font-black'}>Drużyny</p>
            {teams.map((users, index) => {
                return <Team key={index} users={users} index={index}/>
            })}
        </div> : null
    );
}