import { useContext, useRef, useState} from "react";
import {TeamsGeneratorContext} from "../../TeamsGenerator";

export default function UserAdd() {
    const {addUser} = useContext(TeamsGeneratorContext);
    const [username, setUsername] = useState('');
    const inputRef = useRef();

    const handleChange = event => {
        setUsername(event.target.value);
    };

    const handleClick = (event) => {
        onAddUser();
    };

    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            event.preventDefault();
            onAddUser();
        }
    }

    const onAddUser = () => {
        if(username !== ''){
            addUser(username);
            setUsername('')
        }
        inputRef.current.focus();
    }

    return (
        <div>
            <div className={'flex w-full'}>
                <input className={'rounded-l py-2 px-4 w-full text-black focus:outline-none focus:border-transparent focus:ring-0'} ref={inputRef} value={username} onChange={handleChange} onKeyPress={handleKeyPress} type={"text"} placeholder="Nazwa uczestnika"/>
                <button className={'py-2 px-4 bg-amber-500 rounded-r'} onClick={handleClick}>
                    +
                </button>
            </div>
            <p className={'text-xs'}>Możesz zatwierdzać uczestników klawiszem ENTER</p>
        </div>

    )
}