import {useContext} from "react";
import {TeamsGeneratorContext} from "../../TeamsGenerator";
import User from "../../User/User";

export default function UserList() {
    const {users, removeUser} = useContext(TeamsGeneratorContext);

    const onRemove = (index) => {
        removeUser(index)
    }
    return (
        <div className={'grid grid-cols-1 gap-2 mt-4'}>
            {users.length > 0 ? users.map((user, index) => {
                return <User key={index} number={index + 1} username={user} onRemove={() => onRemove(index)}/>
            }) : <p className={'text-center'}>Brak graczy na liście.</p>}
        </div>
    )
}