import UserList from "./UserList/UserList";
import UserAdd from "./UserAdd/UserAdd";

export default function UserManagement(){
    return (
        <div className={'border-amber-500 p-4 rounded'}>
            <p className={'text-sm'}>Dodaj uczestników a następnie kilknij przycisk "losuj drużyny"</p>
            <UserAdd/>
            <UserList/>
        </div>
    )
}